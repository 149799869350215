.active,
.btn:hover {
  /* background-color: #666; */
  color: var(--orange);
}
.active {
  /* background-color: #666; */
  color: var(--orange) !important;
}

.btn {
  /* border: none;
    outline: none;
    padding: 10px 16px; */
  /* background-color: #f1f1f1; */
  cursor: pointer;
  /* font-size: 18px; */
}


