@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --orange: #f07b05;
  --light-black: #43454e;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  scrollbar-width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}


.button {
  background: #f07b05 !important;
  background: var(--orange) !important;
  color: #ffffff !important;
}

.text-white{
  color: #ffffff !important;
}

.button:hover {
  background: #43454e !important;
  background: var(--light-black) !important;
}

.edit {
  color: #43454e !important;
  color: var(--light-black) !important;
  transition: all 400ms !important;
}

.edit:hover {
  color: #ffffff !important;
  background: #43454e !important;
  background: var(--light-black) !important;
}

.delete {
  color: lightcoral !important;
}

.delete:hover {
  color: #ffffff !important;
  background: lightcoral !important;
}

.css-1pahdxg-control{
  border-color: #f07b05 !important;
  box-shadow: 0 0 0 1px #f07b05 !important;
}

.form-control{
  margin-bottom: "1.5rem";
}

.select-button{
  background: #f07b05 !important;
  background: var(--orange) !important;
  color: #ffffff !important;
}

.select-button:hover{
  background: #43454e !important;
  background: var(--light-black) !important;
}

.select-button:focus{
  background: #43454e !important;
  background: var(--light-black) !important;
}

.nodleiveryboy {
  text-align: center;
  background: #fde6e6;
  padding: 16px;
  border: 1px solid #ccc;
  margin-bottom: 24px;
}

.loaderBg{
  display: -webkit-flex;
  display: flex;
    height: 100vh;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.flexDisplay{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 1rem;
}

.flexDisplay > div{
  min-width: 150px;
}

.badge{
  display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 10px;
}

.changpwbg{
  width: "100%";
  height: calc(100% - 100px);
  background: rgb(238, 238, 238);
  position: "relative";
  display: 'flex';
  -webkit-justify-content: "center";
          justify-content: "center";
  -webkit-align-items: "center";
          align-items: "center";
}
.login_form {
    width: 350px;
    height: auto;
    position: relative;
    /* margin-left: 600px; */
    /* margin-top: 180px; */
    background: linear-gradient( to right bottom, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.3) );
    /* transform: translate(-50%, -50%); */
    border-radius: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-animation-name: swipup;
            animation-name: swipup;
    transition: all 400ms ease-in-out;
    -webkit-animation-duration: 400ms;
            animation-duration: 400ms;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 1rem;
}

.logo {
  position: relative;
  height: 1em;
  /* margin-left: 600px; */
  /* margin-top: 180px; */
  font-size: 3rem !important;
  -webkit-animation-name: fadeUp;
          animation-name: fadeUp;
  transition: all 600ms ease-in-out;
  -webkit-animation-duration: 600ms;
          animation-duration: 600ms;
}

@-webkit-keyframes fadeUp {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes fadeUp {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes swipup {
  0% {
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@keyframes swipup {
  0% {
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

.active,
.btn:hover {
  /* background-color: #666; */
  color: var(--orange);
}
.active {
  /* background-color: #666; */
  color: var(--orange) !important;
}

.btn {
  /* border: none;
    outline: none;
    padding: 10px 16px; */
  /* background-color: #f1f1f1; */
  cursor: pointer;
  /* font-size: 18px; */
}



#map {
  height: 500px;
}

/* .MuiInputLabel-outlined {
  transform: translate(14px, -6px) scale(0.75) !important;
} */


  
  .example {
    width: 100%;
    border-radius: 10px;
    margin: 0px;
    padding: 20px;
  }
  
  .item {
    cursor: move;
    font-family: sans-serif;
    /* border-top: 1px solid #999999; */
    background-color: #ffffff;
    /* border-bottom: 1px solid #999999; */
    border-radius: 6px;
    margin-bottom: 4px;
    padding: 10px;
    padding-left: 8px;
    opacity: 1;
  }
  
  .item .title {
    font-weight: bold;
    font-size: 125%;
    margin: 0;
    margin-bottom: 0.3rem;
  }
  
  .item .body {
    margin: 0;
    margin-bottom: 0.3rem;
  }
  
  .item .small {
    font-size: 66%;
    text-align: right;
  }
  
  .dragged .item {
    cursor: move;
    opacity: 0.25;
    border-top: 1px solid #999999;
    border-bottom: 1px solid #999999;
  }
  
  .floating .item {
    cursor: move;
    background-color: #ffffff;
    box-shadow: 0 4px 20px #666666;
  }
  
  .dl-item.floating {
    width: 85%;
    cursor: move;
  }

  .image_size{
    height: 150px;
    width: 200px;
    border-radius: 10px;
  }
  
