.login_form {
    width: 350px;
    height: auto;
    position: relative;
    /* margin-left: 600px; */
    /* margin-top: 180px; */
    background: linear-gradient( to right bottom, rgba(255, 255, 255, 0.35), rgba(255, 255, 255, 0.3) );
    /* transform: translate(-50%, -50%); */
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-name: swipup;
    transition: all 400ms ease-in-out;
    animation-duration: 400ms;
    flex-direction: column;
    padding: 1rem;
}

.logo {
  position: relative;
  height: 1em;
  /* margin-left: 600px; */
  /* margin-top: 180px; */
  font-size: 3rem !important;
  animation-name: fadeUp;
  transition: all 600ms ease-in-out;
  animation-duration: 600ms;
}

@keyframes fadeUp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes swipup {
  0% {
    transform: translate(0, 100%);
  }
  100% {
    transform: translate(0, 0);
  }
}
