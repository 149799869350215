@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --orange: #f07b05;
  --light-black: #43454e;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  scrollbar-width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}


.button {
  background: var(--orange) !important;
  color: #ffffff !important;
}

.text-white{
  color: #ffffff !important;
}

.button:hover {
  background: var(--light-black) !important;
}

.edit {
  color: var(--light-black) !important;
  transition: all 400ms !important;
}

.edit:hover {
  color: #ffffff !important;
  background: var(--light-black) !important;
}

.delete {
  color: lightcoral !important;
}

.delete:hover {
  color: #ffffff !important;
  background: lightcoral !important;
}

.css-1pahdxg-control{
  border-color: #f07b05 !important;
  box-shadow: 0 0 0 1px #f07b05 !important;
}

.form-control{
  margin-bottom: "1.5rem";
}

.select-button{
  background: var(--orange) !important;
  color: #ffffff !important;
}

.select-button:hover{
  background: var(--light-black) !important;
}

.select-button:focus{
  background: var(--light-black) !important;
}

.nodleiveryboy {
  text-align: center;
  background: #fde6e6;
  padding: 16px;
  border: 1px solid #ccc;
  margin-bottom: 24px;
}

.loaderBg{
  display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.flexDisplay{
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.flexDisplay > div{
  min-width: 150px;
}

.badge{
  display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 10px;
}

.changpwbg{
  width: "100%";
  height: calc(100% - 100px);
  background: rgb(238, 238, 238);
  position: "relative";
  display: 'flex';
  justify-content: "center";
  align-items: "center";
}