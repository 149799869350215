
  
  .example {
    width: 100%;
    border-radius: 10px;
    margin: 0px;
    padding: 20px;
  }
  
  .item {
    cursor: move;
    font-family: sans-serif;
    /* border-top: 1px solid #999999; */
    background-color: #ffffff;
    /* border-bottom: 1px solid #999999; */
    border-radius: 6px;
    margin-bottom: 4px;
    padding: 10px;
    padding-left: 8px;
    opacity: 1;
  }
  
  .item .title {
    font-weight: bold;
    font-size: 125%;
    margin: 0;
    margin-bottom: 0.3rem;
  }
  
  .item .body {
    margin: 0;
    margin-bottom: 0.3rem;
  }
  
  .item .small {
    font-size: 66%;
    text-align: right;
  }
  
  .dragged .item {
    cursor: move;
    opacity: 0.25;
    border-top: 1px solid #999999;
    border-bottom: 1px solid #999999;
  }
  
  .floating .item {
    cursor: move;
    background-color: #ffffff;
    box-shadow: 0 4px 20px #666666;
  }
  
  .dl-item.floating {
    width: 85%;
    cursor: move;
  }

  .image_size{
    height: 150px;
    width: 200px;
    border-radius: 10px;
  }
  